@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    h2 {
        @apply text-blue-dark;
    }

    .transparent-black {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.spinner {
    animation: spin infinite 1s linear;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.show-scrollbar::-webkit-scrollbar {
    display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
.show-scrollbar {
    -ms-overflow-style: block;  /* IE and Edge */
    scrollbar-width: block;  /* Firefox */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
