@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/heebo";
@import "styles.css";

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }


  /* Firefox */
* {
  
  scrollbar-color: #ffbe00 #EEE;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffbe00;
  border-radius: 20px;
  border: 2px solid #F5F5F5;
}